export const userInfo = [
  {
    index: 1,
    name: "汤梁峰",
    enrollment_time: 2000.9,
    graduation_time: 2007.7,
    supervisor: "朱同玉",
    address: "上海市",
    employer: "复旦大学附属儿科医院/泌尿外科",
    office: null,
    post: "副主任医师（具有肾移 植医师资质）",
    project: "肾脏缺血再灌注损伤及改善",
    photo: true,
    position: ["121.394971", "31.139147"],
  },
  {
    index: 2,
    name: "李龙",
    enrollment_time: 2011.9,
    graduation_time: 2016.7,
    supervisor: "朱同玉/戎瑞明",
    address: "上海市",
    employer: "上海交通大学医学院附属第九人民医院泌尿外科",
    office: null,
    post: "副主任医师",
    project: "肾移植免疫耐受",
    photo: true,
    position: ["121.40", "31.21"]
  },
  {
    index: 3,
    name: "李金锋",
    enrollment_time: 2004.09,
    graduation_time: 2009.07,
    supervisor: "朱同玉，王国民",
    address: "郑州市",
    employer: "郑州大学第一附属医院",
    office: null,
    post: "主任医师，教授",
    project: "肾移植",
    photo: true,
    position: ["113.646042", "34.752734"],
  },
  {
    index: 4,
    name: "俞旻皓",
    enrollment_time: 2001.9,
    graduation_time: 2008.7,
    supervisor: "朱同玉",
    address: "上海市",
    employer: "上海交通大学医学院附属仁济医院",
    office: null,
    post: "副主任医师",
    project: "肾脏缺血再灌注损伤",
    photo: true,
    position: ["121.484270", "31.233971"],
  },
  {
    index: 5,
    name: "孟军",
    enrollment_time: 2000.9,
    graduation_time: 2007.7,
    supervisor: "朱同玉",
    address: "上海市",
    employer: "复旦大学附属中山医院吴淞医院",
    office: "评审办",
    post: "副主任医师",
    project: "尿沉淀细胞DNA甲基化状态在膀胱癌诊断中的价值评估",
    photo: true,
    position: ["121.496298", "31.377933"],
  },
  {
    index: 6,
    name: "李佳蔚",
    enrollment_time: 2015.9,
    graduation_time: 2021.7,
    supervisor: "戎瑞明",
    address: "上海市",
    employer: "abbvie",
    office: null,
    post: "senior clinical research physician",
    project: "肾移植免疫耐受",
    photo: true,
    position: ["121.463239", "31.228666"],
  },
  {
    index: 7,
    name: "彭博",
    enrollment_time: 2008.9,
    graduation_time: 2016.7,
    supervisor: "朱同玉",
    address: "长沙市",
    employer: "中南大学湘雅三医院",
    office: null,
    post: "主治医师",
    project: "肾移植免疫耐受",
    photo: true,
    position: ["112.944798", "28.220483"],
  },
  {
    index: 8,
    name: "邱月",
    enrollment_time: 2009.9,
    graduation_time: 2012.6,
    supervisor: "戎瑞明",
    "现住 址（省/市）": "青岛市",
    employer: "山东大学齐鲁医院青岛院区",
    office: null,
    post: "副主任医师",
    project: "肾缺血再灌注损伤",
    photo: true,
    position: ["117.018773", "36.656637"],
  },
  {
    index: 9,
    name: "戚若晨",
    enrollment_time: 2018.9,
    graduation_time: 2021.6,
    supervisor: "朱同玉",
    address: "西安市",
    employer: "空军军医大学西京医院",
    office: null,
    post: "主治医师",
    project: "肾脏纤维化",
    photo: true,
    position: ["108.9430", "34.2739"]
  },
  {
    index: 10,
    name: "王鸣",
    enrollment_time: 2006.9,
    graduation_time: 2009.6,
    supervisor: "朱同玉",
    address: "杭州",
    employer: "浙江大学医学院附属第二医院",
    office: null,
    post: "副主任医师",
    project: "造血干细胞免疫逃逸",
    photo: true,
    position: ["120.177439", "30.251172"],
  },
  {
    index: 11,
    name: "顾辰力",
    enrollment_time: 2007.9,
    graduation_time: 2015.7,
    supervisor: "朱同玉",
    address: "上海市",
    employer: "和睦家医疗集团",
    office: null,
    post: "战略总监",
    project: "肾缺血再灌注损伤",
    photo: true,
    position: ["121.369253", "31.205431"]
  },
  {
    index: 12,
    name: "王汝霖",
    enrollment_time: 2006.9,
    graduation_time: 2014.6,
    supervisor: "许明",
    address: "美国",
    employer: "约翰霍普金斯大学医学院",
    office: null,
    post: "Research Associate",
    project: "肾缺血再灌注损伤",
    photo: true,
    position: ["-76.62103", "39.33008"]
  },
  {
    index: 13,
    name: "林淼",
    enrollment_time: 2004.9,
    graduation_time: 2013.9,
    supervisor: "朱同玉/戎瑞明",
    address: "上海市",
    employer: "复旦大学附属中山医院",
    office: null,
    post: "副主任医师",
    project: "肾缺血再灌注损伤",
    photo: true,
    position: ["121.454965", "31.197318"],
  },
  {
    index: 14,
    name: "屠国伟",
    enrollment_time: 2001.9,
    graduation_time: 2008.7,
    supervisor: "朱同玉",
    address: "上海市",
    employer: "复旦大学附属中山医院",
    office: null,
    post: "主任医师",
    project: "肾移植术后BK病毒感染",
    photo: true,
    position: ["121.454965", "31.197318"],
  },
  {
    index: 15,
    name: "李海皓",
    enrollment_time: 2006.9,
    graduation_time: 2014.6,
    supervisor: " 朱同玉",
    address: "云南省昆明市",
    employer: "昆明医科大学第二附属医院",
    office: "泌尿外科",
    post: "副教授",
    project: "肾移植免疫耐受",
    photo: true,
    position: ["102.680211", "25.026262"],
  },
  {
    index: 16,
    name: "邱甬鄞",
    enrollment_time: 2004.9,
    graduation_time: 2012.6,
    supervisor: "朱同玉",
    address: "新加坡",
    employer: "新加坡中央医院",
    office: "急诊医学科",
    post: "Associate Consultant",
    project: "肾移植免疫耐受",
    photo: true,
    position: ["103.833947", "1.273867"]
  },
  {
    index: 17,
    name: "黎力平",
    enrollment_time: 2012.9,
    graduation_time: 2016.1,
    supervisor: "朱同玉",
    address: "美国宾夕法尼亚州Danville",
    employer: "Geisinger Medical Center",
    office: "病理科",
    post: "住院医生",
    project: "肾脏缺血再灌注损伤",
    photo: true,
    position: ["-76.60575", "40.96772"]
  },
  {
    index: 18,
    name: "陈俊涛",
    enrollment_time: 2020.9,
    graduation_time: 2023.6,
    supervisor: "朱同玉",
    address: "杭州",
    employer: "浙江大学医学院附属第二医院",
    office: null,
    post: "住院医生",
    project: "肾缺血再灌注损失",
    photo: true,
    position: ["120.177439", "30.251172"],
  },
  {
    index: 19,
    name: "张潮",
    enrollment_time: 2012.9,
    graduation_time: 2019.6,
    supervisor: "朱同玉/戎瑞明",
    address: "上海市",
    employer: "复旦大学附属肿瘤医院",
    office: null,
    post: "主治医师",
    project: "肾缺血再灌注损伤",
    photo: null,
    position: ["121.369253", "31.205431"]
  },
  {
    index: 20,
    name: "王学雷",
    enrollment_time: 2002.9,
    graduation_time: 2005.6,
    supervisor: "朱同玉",
    address: "上海市",
    employer: "同济大学附属东方医院",
    office: "泌尿外科",
    post: "主任医师",
    project: "干细胞免疫逃逸",
    photo: true,
    position: ["121.513035", "31.238288"]
  },
  {
    index: 21,
    name: "卓然",
    enrollment_time: 2019.9,
    graduation_time: 2022.6,
    supervisor: "戎瑞明",
    address: "香港",
    employer: "香港大学医学院",
    office: null,
    post: "博后",
    project: "肾纤维化",
    photo: null,
    position: ["114.1313", "22.2820"]
  },
  {
    index: 22,
    name: "GAURAB POKHREL",
    enrollment_time: 2011.9,
    graduation_time: 2014.6,
    supervisor: "戎瑞明",
    address: "NEPAL,KATHMANDU",
    employer: "HELPING HANDS COMMUNITY HOSPITAL",
    office: "UROLOGY",
    post: "Consultant",
    project: null,
    photo: null,
    position: ["85.3240", "27.7084"]
  },
  {
    index: 23,
    name: "高文君",
    enrollment_time: 2017.9,
    graduation_time: 2021.6,
    supervisor: "朱同玉",
    address: "杭州",
    employer: "浙江大学医学院附属第二医院",
    office: null,
    post: "主治医师",
    project: "肾缺血再灌注损伤",
    photo: true,
    position: ["120.177439", "30.251172"],
  },
  {
    index: 24,
    name: "陈天",
    enrollment_time: 2013.9,
    graduation_time: 2021.6,
    supervisor: "戎瑞明",
    address: "新加坡",
    employer: "邱德拔医院 Khoo Teck Puat Hospital (KTPH)",
    office: "泌尿科",
    post: "medical officer",
    project: "肾缺血再灌注损伤",
    photo: true,
    position: ["1.424635", "103.838208"]
  },
  {
    index: 25,
    name: "罗永胜",
    enrollment_time: 2020.9,
    graduation_time: 2023.6,
    supervisor: "戎瑞明",
    address: "河南省郑州市",
    employer: "郑州大学第一附属医院",
    office: "肾移植科",
    post: "住院医师",
    project: "肾纤维化",
    photo: true,
    position: ["113.646042", "34.752734"],
  },
  {
    index: 26,
    name: "张畅",
    enrollment_time: 2000.9,
    graduation_time: 2007.7,
    supervisor: "朱同玉/孙立安",
    address: "上海市",
    employer: "上海市同仁医院",
    office: "教学科",
    post: "主治医师",
    project: "雷帕霉素对大鼠肾缺血再灌注损伤的影响",
    photo: null,
    position: ["121.3783", "31.2216"]
  },
  {
    index: 27,
    name: "王硕",
    enrollment_time: 2014.9,
    graduation_time: 2017.6,
    supervisor: "朱同玉",
    address: "山东省济南市",
    employer: "山东大学齐鲁医院",
    office: "泌尿外科",
    post: "主治医师",
    project: "肾缺血再灌注损伤",
    photo: true,
    position: ["117.018773", "36.656637"],
  },
  {
    index: 28,
    name: "徐世豪",
    enrollment_time: 2019.9,
    graduation_time: 2022.6,
    supervisor: "戎瑞明",
    address: "山东省济南市",
    employer: "山东第一医科大学附属省立医院",
    office: "泌尿外科",
    post: " 主治医师",
    project: "肾纤维化",
    photo: true,
    position: ["116.9813", "36.6543"]
  },
  {
    index: 29,
    name: "张豪杰",
    enrollment_time: 2009.9,
    graduation_time: 2014.6,
    supervisor: "朱同玉",
    address: "上海",
    employer: "复旦大学附属华东医院",
    office: "泌尿外科",
    post: "副主任医师",
    project: "Twist2对肾癌细胞功能和肿瘤发展影响的可能的分子机制",
    photo: true,
    position: ["121.4327", "31.2313"]
  },
  {
    index: 30,
    name: "张平宝",
    enrollment_time: 2020.9,
    graduation_time: 2023.12,
    supervisor: "戎瑞明",
    address: "上海市",
    employer: "同济大学附属东方医院",
    office: "泌尿外科",
    post: "住院医师",
    project: "肾纤维化",
    photo: true,
    position: ["121.513035", "31.238288"]
  },
  {
    index: 31,
    name: "王吉妍",
    enrollment_time: 2019.9,
    graduation_time: 2022.12,
    supervisor: "朱同玉",
    address: "上海",
    employer: "复旦大学附属中山医院",
    office: "检验科",
    post: "住院医师",
    project: "肾脏缺血再灌注损伤",
    photo: true,
    position: ["121.454965", "31.197318"],
  },
  {
    index: 32,
    name: "孙佳佳",
    enrollment_time: 2018.9,
    graduation_time: 2021.6,
    supervisor: "朱同玉",
    address: "郑州市",
    employer: "郑州大学第一附属医院",
    office: "肾移植科",
    post: "住院医师",
    project: "BK多瘤病毒感染防治",
    photo: true,
    position: ["113.646042", "34.752734"],
  },
  {
    index: 33,
    name: "许瀚仁",
    enrollment_time: 2018.9,
    graduation_time: 2021.6,
    supervisor: "许明",
    address: "深圳",
    employer: "中山大学附属第八医院",
    office: "急诊科",
    post: "住院医师",
    project: "BK多瘤病毒感染防治",
    photo: true,
    position: ["114.0564", "22.5455"]
  },
  {
    index: 34,
    name: "郑龙",
    enrollment_time: 2013.9,
    graduation_time: 2016.7,
    supervisor: "戎瑞明",
    address: "杭州市",
    employer: "浙江大学医学院附属第二医院",
    office: " 泌尿外科",
    post: "主治医师",
    project: "肾移植与免疫耐受",
    photo: true,
    position: ["120.177439", "30.251172"],
  },
  {
    index: 35,
    name: "张乐希",
    enrollment_time: 2007.9,
    graduation_time: 2015.7,
    supervisor: "戎瑞明",
    address: "合肥市",
    employer: "中科大附属第一医院（安徽省立医院）",
    office: "肾移植科",
    post: "主治医师",
    project: " 肾缺血再灌注损伤",
    photo: true,
    position: ["117.2804", "31.8480"]
  },
  {
    index: 36,
    name: "胡沐霜",
    enrollment_time: 2009.9,
    graduation_time: 2017.6,
    supervisor: "朱同玉",
    address: "上海市",
    employer: "我武生物",
    office: null,
    post: "子公司总经理",
    project: "人肾telocyes",
    photo: true,
    position: ["120.0029", "30.6099"]
  },
  {
    index: 37,
    name: "蒋亚梅",
    enrollment_time: 2019.9,
    graduation_time: 2023.6,
    supervisor: "朱同玉",
    address: "北京市",
    employer: "首都医科大学宣武医院",
    office: "泌尿外科",
    post: "住院医师",
    project: "肾脏缺血再灌注损伤",
    photo: true,
    position: ["116.362275", "39.891298"],
  },
  {
    index: 38,
    name: "黄尚",
    enrollment_time: 2007.9,
    graduation_time: 2015.7,
    supervisor: "朱同玉",
    address: "广州市",
    employer: "广东省人民医院",
    office: "泌尿外科",
    post: "主治医师",
    project: "肾缺血再灌注损伤",
    photo: true,
    position: ["113.287272", "23.125087"],
  },
  {
    index: 39,
    name: "曾毅刚",
    enrollment_time: 2016.9,
    graduation_time: null,
    supervisor: "朱同玉",
    address: "上海市",
    employer: "上海市第一人民医院",
    office: "泌尿外科",
    post: "主治医师",
    project: "BK多瘤病毒感染防治",
    photo: null,
    position: ["121.489273", "31.253352"],
  },
];
