const pcC = [
  {
    code: "11",
    name: "Beijing Municipality",
    children: [
      { code: "110101", name: "Dongcheng District" },
      { code: "110101", name: "Dongcheng District" },
      { code: "110102", name: "Xicheng District" },
      { code: "110105", name: "Chaoyang District" },
      { code: "110105", name: "Chaoyang District" },
      { code: "110106", name: "Fengtai District" },
      { code: "110107", name: "石景山区" },
      { code: "110108", name: "Haidian District" },
      { code: "110109", name: "Mentougou District" },
      { code: "110111", name: "Fangshan District" },
      { code: "110112", name: "Tongzhou District" },
      { code: "110113", name: "Shunyi District" },
      { code: "110114", name: "Changping District" },
      { code: "110115", name: "Daxing District" },
      { code: "110116", name: "Huairou District" },
      { code: "110117", name: "Pinggu District" },
      { code: "110118", name: "Miyun District" },
      { code: "110119", name: "Yanqing District" },
    ],
  },
  {
    code: "12",
    name: "tianjin",
    children: [
      { code: "120101", name: "Heping District" },
      { code: "120101", name: "Heping District" },
      { code: "120102", name: "Hedong District" },
      { code: "120103", name: "Hexi District" },
      { code: "120103", name: "Hexi District" },
      { code: "120104", name: "Nankai District" },
      { code: "120105", name: "HBZ" },
      { code: "120106", name: "Hongqiao District" },
      { code: "120110", name: "Dongli District" },
      { code: "120111", name: "Xizheng District" },
      { code: "120112", name: "Jinnan District" },
      { code: "120113", name: "North Star District" },
      { code: "120114", name: "Wuqing District" },
      { code: "120115", name: "Baodi District" },
      { code: "120116", name: "Binhai New Area" },
      { code: "120117", name: "Ninghe District" },
      { code: "120118", name: "Jinghai District" },
      { code: "120119", name: "Thistledown District" },
    ],
  },
  {
    code: "13",
    name: "Hebei Province",
    children: [
      { code: "1301", name: "Shijiazhuang City" },
      { code: "1302", name: "Tangshan City" },
      { code: "1303", name: "Qinhuangdao City" },
      { code: "1304", name: "Handan City" },
      { code: "1305", name: "Xingtai City" },
      { code: "1306", name: "Baoding City" },
      { code: "1307", name: "Zhangjiakou City" },
      { code: "1308", name: "Chengde City" },
      { code: "1309", name: "Cangzhou City" },
      { code: "1310", name: "Langfang City" },
      { code: "1311", name: "Hengshui City" },
    ],
  },
  {
    code: "14",
    name: "Shanxi Province",
    children: [
      { code: "1401", name: "Taiyuan City" },
      { code: "1402", name: "Datong City" },
      { code: "1403", name: "Yangquan City" },
      { code: "1404", name: "Changzhi City" },
      { code: "1405", name: "Jincheng City" },
      { code: "1406", name: "Shuozhou City" },
      { code: "1407", name: "Jinzhong City" },
      { code: "1408", name: "Yuncheng City" },
      { code: "1409", name: "Xinzhou City" },
      { code: "1410", name: "Linfen City" },
      { code: "1411", name: "Lvliang City" },
    ],
  },
  {
    code: "15",
    name: "Inner Mongolia Autonomous Region",
    children: [
      { code: "1501", name: "Hohhot City" },
      { code: "1502", name: "Baotou City" },
      { code: "1503", name: "UHI" },
      { code: "1504", name: "Chifeng City" },
      { code: "1505", name: "Tongliao City" },
      { code: "1506", name: "Ordos City" },
      { code: "1507", name: "Hulunbeier City" },
      { code: "1508", name: "Bayan Nur City" },
      { code: "1509", name: "Ulaanbaatar City" },
      { code: "1522", name: "Xing'anmeng" },
      { code: "1525", name: "Xilin Gol League" },
      { code: "1529", name: "ALASKA" },
    ],
  },
  {
    code: "21",
    name: "Liaoning Province",
    children: [
      { code: "2101", name: "Shenyang City" },
      { code: "2102", name: "Dalian City" },
      { code: "2103", name: "Anshan City" },
      { code: "2104", name: "Fushun City" },
      { code: "2105", name: "Benxi City" },
      { code: "2105", name: "Benxi City" },
      { code: "2106", name: "Dandong City" },
      { code: "2107", name: "Jinzhou City" },
      { code: "2107", name: "Jinzhou City" },
      { code: "2108", name: "Yingkou City" },
      { code: "2109", name: "Fuxin City" },
      { code: "2110", name: "Liaoyang City" },
      { code: "2111", name: "Panjin City" },
      { code: "2112", name: "Tieling City" },
      { code: "2113", name: "Chaoyang City" },
      { code: "2113", name: "Chaoyang City" },
      { code: "2114", name: "Huludao City" },
    ],
  },
  {
    code: "22",
    name: "Jilin Province",
    children: [
      { code: "2201", name: "Changchun City" },
      { code: "2202", name: "Jilin City" },
      { code: "2203", name: "Siping City" },
      { code: "2204", name: "Liaoyuan City" },
      { code: "2205", name: "Tonghua City" },
      { code: "2206", name: "Hakusan City" },
      { code: "2206", name: "Hakusan City" },
      { code: "2207", name: "Matsubara City" },
      { code: "2208", name: "White City" },
      { code: "2208", name: "White City" },
      { code: "2224", name: "Yanbian Korean Autonomous Prefecture" },
    ],
  },
  {
    code: "23",
    name: "Heilongjiang Province",
    children: [
      { code: "2301", name: "Harbin City" },
      { code: "2302", name: "Qiqihar City" },
      { code: "2303", name: "Jixi City" },
      { code: "2304", name: "Hegang City" },
      { code: "2305", name: "Shuangyashan City" },
      { code: "2306", name: "Daqing City" },
      { code: "2307", name: "Yichun City" },
      { code: "2308", name: "Jiamusi City" },
      { code: "2309", name: "Qitaihe City" },
      { code: "2310", name: "Mudanjiang City" },
      { code: "2311", name: "Heihe City" },
      { code: "2311", name: "Heihe City" },
      { code: "2312", name: "Suihua City" },
      { code: "2327", name: "Daxinganling Region" },
    ],
  },
  {
    code: "31",
    name: "Shanghai Municipality",
    children: [
      { code: "310101", name: "Huangpu District" },
      { code: "310104", name: "Xuhui District" },
      { code: "310105", name: "Changning District" },
      { code: "310106", name: "Jing'an District" },
      { code: "310106", name: "Jing'an District" },
      { code: "310107", name: "Putuo District" },
      { code: "310109", name: "Hongkou District" },
      { code: "310110", name: "Yangpu District" },
      { code: "310112", name: "Minhang District" },
      { code: "310113", name: "Baoshan District" },
      { code: "310114", name: "Jiading District" },
      { code: "310115", name: "Pudong New Area" },
      { code: "310116", name: "Jinshan District" },
      { code: "310117", name: "Songjiang District" },
      { code: "310118", name: "qingpu district" },
      { code: "310120", name: "Fengxian District" },
      { code: "310151", name: "Chongming District" },
    ],
  },
  {
    code: "32",
    name: "Jiangsu Province",
    children: [
      { code: "3201", name: "Nanjing" },
      { code: "3202", name: "Wuxi City" },
      { code: "3203", name: "Xuzhou City" },
      { code: "3204", name: "Changzhou City" },
      { code: "3205", name: "Suzhou City" },
      { code: "3206", name: "Nantong City" },
      { code: "3207", name: "Lianyungang City" },
      { code: "3208", name: "Huaian City" },
      { code: "3209", name: "Yancheng City" },
      { code: "3210", name: "Yangzhou City" },
      { code: "3211", name: "Zhenjiang City" },
      { code: "3212", name: "Taizhou City" },
      { code: "3213", name: "Cebu City" },
    ],
  },
  {
    code: "33",
    name: "Zhejiang Province",
    children: [
      { code: "3301", name: "Hangzhou" },
      { code: "3302", name: "Ningbo City" },
      { code: "3303", name: "Wenzhou City" },
      { code: "3304", name: "Jiaxing City" },
      { code: "3305", name: "Huzhou City" },
      { code: "3306", name: "Shaoxing City" },
      { code: "3307", name: "Jinhua City" },
      { code: "3308", name: "QuZhou" },
      { code: "3309", name: "Zhoushan City" },
      { code: "3310", name: "Taizhou City" },
      { code: "3311", name: "Lishui City" },
    ],
  },
  {
    code: "34",
    name: "Anhui Province",
    children: [
      { code: "3401", name: "Hefei City" },
      { code: "3402", name: "Wuhu City" },
      { code: "3403", name: "Bengbu City" },
      { code: "3404", name: "Huainan City" },
      { code: "3405", name: "Ma On Shan City" },
      { code: "3406", name: "Huaibei City" },
      { code: "3407", name: "Tongling City" },
      { code: "3408", name: "Anqing City" },
      { code: "3410", name: "Huangshan City" },
      { code: "3411", name: "Chuzhou City" },
      { code: "3412", name: "Fuyang City" },
      { code: "3413", name: "Cebu City" },
      { code: "3415", name: "Lu'an City" },
      { code: "3416", name: "Bozhou City" },
      { code: "3417", name: "Ikebukuro" },
      { code: "3418", name: "Xuancheng" },
    ],
  },
  {
    code: "35",
    name: "Fujian Province",
    children: [
      { code: "3501", name: "Fuzhou City" },
      { code: "3502", name: "Xiamen City" },
      { code: "3503", name: "PUTIEN" },
      { code: "3504", name: "Sanming City" },
      { code: "3505", name: "Quanzhou City" },
      { code: "3506", name: "Zhangzhou City" },
      { code: "3507", name: "Nanping City" },
      { code: "3508", name: "Longyan City" },
      { code: "3509", name: "Ningde City" },
    ],
  },
  {
    code: "36",
    name: "Jiangxi Province",
    children: [
      { code: "3601", name: "Nanchang" },
      { code: "3602", name: "Jingdezhen City" },
      { code: "3603", name: "Pingxiang City" },
      { code: "3604", name: "Jiujiang City" },
      { code: "3605", name: "Xinyu City" },
      { code: "3606", name: "Yingtan City" },
      { code: "3607", name: "Ganzhou City" },
      { code: "3608", name: "Ji'an" },
      { code: "3609", name: "Yichun City" },
      { code: "3610", name: "Fuzhou City" },
      { code: "3611", name: "Shangrao City" },
    ],
  },
  {
    code: "37",
    name: "Shandong province",
    children: [
      { code: "3701", name: "Jinan City" },
      { code: "3702", name: "Qingdao City" },
      { code: "3703", name: "Zibo City" },
      { code: "3704", name: "Zaozhuang City" },
      { code: "3705", name: "Dongying City" },
      { code: "3706", name: "Yantai City" },
      { code: "3707", name: "Weifang City" },
      { code: "3708", name: "Jining City" },
      { code: "3709", name: "Tai'an City" },
      { code: "3710", name: "Weihai City" },
      { code: "3711", name: "Rizhao City" },
      { code: "3713", name: "Linyi City" },
      { code: "3714", name: "Texas City" },
      { code: "3715", name: "Liaocheng City" },
      { code: "3716", name: "Binzhou City" },
      { code: "3717", name: "Heze City" },
    ],
  },
  {
    code: "41",
    name: "Henan Province",
    children: [
      { code: "4101", name: "Zhengzhou City" },
      { code: "4102", name: "Kaifeng City" },
      { code: "4103", name: "Luoyang City" },
      { code: "4104", name: "Pingdingshan City" },
      { code: "4105", name: "Anyang City" },
      { code: "4105", name: "Anyang City" },
      { code: "4106", name: "Hebi City" },
      { code: "4107", name: "Xinxiang City" },
      { code: "4108", name: "Jiaozuo City" },
      { code: "4109", name: "Puyang City" },
      { code: "4110", name: "Xuchang City" },
      { code: "4111", name: "Luohe City" },
      { code: "4112", name: "Sanmenxia City" },
      { code: "4113", name: "Nanyang City" },
      { code: "4113", name: "Nanyang City" },
      { code: "4114", name: "Shangqiu City" },
      { code: "4115", name: "Xinyang City" },
      { code: "4116", name: "Zhoukou City" },
      { code: "4117", name: "Zhumadian City" },
      { code: "419001", name: "Jiyuan City" },
    ],
  },
  {
    code: "42",
    name: "Hubei Province",
    children: [
      { code: "4201", name: "Wuhan City" },
      { code: "4202", name: "Huangshi City" },
      { code: "4203", name: "Shiyan City" },
      { code: "4205", name: "Yichang City" },
      { code: "4206", name: "Xiangyang City" },
      { code: "4207", name: "Ezhou City" },
      { code: "4208", name: "Jingmen City" },
      { code: "4209", name: "Xiaogan City" },
      { code: "4210", name: "Jingzhou City" },
      { code: "4211", name: "Huanggang City" },
      { code: "4212", name: "Xianning City" },
      { code: "4213", name: "Suizhou City" },
      { code: "4228", name: "Enshi Tujia and Miao Autonomous Prefecture" },
      { code: "429004", name: "Xiantao City" },
      { code: "429005", name: "Qianjiang City" },
      { code: "429006", name: "Tianmen City" },
      { code: "429021", name: "Shennongjia Forest District" },
    ],
  },
  {
    code: "43",
    name: "Hunan Province",
    children: [
      { code: "4301", name: "Changsha City" },
      { code: "4302", name: "Zhuzhou City" },
      { code: "4303", name: "Xiangtan City" },
      { code: "4304", name: "Hengyang City" },
      { code: "4305", name: "Shaoyang City" },
      { code: "4306", name: "Yueyang City" },
      { code: "4307", name: "Changde City" },
      { code: "4308", name: "Zhangjiajie City" },
      { code: "4309", name: "Yiyang City" },
      { code: "4310", name: "Chenzhou City" },
      { code: "4311", name: "Yongzhou City" },
      { code: "4312", name: "Huaihua City" },
      { code: "4313", name: "Lourdes" },
      { code: "4331", name: "Xiangxi Tujia and Miao Autonomous Prefecture" },
    ],
  },
  {
    code: "44",
    name: "Guangdong Province",
    children: [
      { code: "4401", name: "Guangzhou City" },
      { code: "4401", name: "Guangzhou City" },
      { code: "4402", name: "Shaoguan City" },
      { code: "4403", name: "Shenzhen City" },
      { code: "4404", name: "Zhuhai City" },
      { code: "4405", name: "Shantou City" },
      { code: "4406", name: "Foshan City" },
      { code: "4407", name: "Jiangmen City" },
      { code: "4408", name: "Zhanjiang" },
      { code: "4409", name: "Maoming City" },
      { code: "4412", name: "Zhaoqing City" },
      { code: "4413", name: "Huizhou City" },
      { code: "4414", name: "Meizhou City" },
      { code: "4415", name: "Shanwei City" },
      { code: "4416", name: "Heyuan City" },
      { code: "4417", name: "Yangjiang City" },
      { code: "4418", name: "Qingyuan City" },
      { code: "4419", name: "Dongguan City" },
      { code: "4420", name: "Zhongshan City" },
      { code: "4451", name: "Chaozhou City" },
      { code: "4452", name: "Jieyang City" },
      { code: "4453", name: "Yunfu City" },
    ],
  },
  {
    code: "45",
    name: "Guangxi Zhuang Autonomous Region",
    children: [
      { code: "4501", name: "Nanning City" },
      { code: "4502", name: "Liuzhou City" },
      { code: "4503", name: "Guilin City" },
      { code: "4504", name: "Wuzhou City" },
      { code: "4505", name: "Beihai City" },
      { code: "4506", name: "Fangchenggang City" },
      { code: "4507", name: "Qinzhou City" },
      { code: "4508", name: "Guigang City" },
      { code: "4509", name: "Yulin City" },
      { code: "4510", name: "Baise City" },
      { code: "4511", name: "HeZhou" },
      { code: "4512", name: "Ho Chi Minh City" },
      { code: "4513", name: "Laibin City" },
      { code: "4514", name: "Chongzuo City" },
    ],
  },
  {
    code: "46",
    name: "Hainan Province",
    children: [
      { code: "4601", name: "Haikou City" },
      { code: "4602", name: "Sanya City" },
      { code: "4603", name: "Sansha City" },
      { code: "4604", name: "Danzhou City" },
      { code: "469001", name: "Wuzhishan City" },
      { code: "469002", name: "Qionghai City" },
      { code: "469005", name: "Wenchang City" },
      { code: "469006", name: "Municipality of Manning" },
      { code: "469007", name: "Orient City" },
      { code: "469021", name: "Ding'an County" },
      { code: "469022", name: "Tunchang County" },
      { code: "469023", name: "澄迈县" },
      { code: "469024", name: "Lingao County" },
      { code: "469025", name: "Baisha Lizu Autonomous County" },
      { code: "469026", name: "Changjiang Lizu Autonomous County" },
      { code: "469027", name: "Ledong Lizu Autonomous County" },
      { code: "469028", name: "Lingshui Lizu autonomous county" },
      { code: "469029", name: "Baoting Li and Miao Autonomous County" },
      { code: "469030", name: "Qiongzhong Li and Miao Autonomous County" },
    ],
  },
  {
    code: "50",
    name: "Chongqing Municipality",
    children: [
      { code: "500101", name: "Wanzhou District" },
      { code: "500102", name: "Fuling District" },
      { code: "500103", name: "Yuzhong District" },
      { code: "500104", name: "Dadukou District" },
      { code: "500105", name: "Jiangbei District" },
      { code: "500106", name: "Shapingba District" },
      { code: "500107", name: "Jiulongpo District" },
      { code: "500108", name: "South Bank District" },
      { code: "500109", name: "Beibei District" },
      { code: "500110", name: "Qijiang District" },
      { code: "500111", name: "Dazu District" },
      { code: "500112", name: "Yubei District" },
      { code: "500113", name: "Banan District" },
      { code: "500114", name: "Qianjiang District" },
      { code: "500115", name: "Changshou District" },
      { code: "500116", name: "Jiangjin District" },
      { code: "500117", name: "Hechuan District" },
      { code: "500118", name: "Yongchuan District" },
      { code: "500119", name: "Nanchuan District" },
      { code: "500120", name: "Bishan District" },
      { code: "500151", name: "Tongliang District" },
      { code: "500152", name: "Tongnan District" },
      { code: "500153", name: "Rongchang District" },
      { code: "500154", name: "Kaiju District" },
      { code: "500155", name: "Liangping District" },
      { code: "500156", name: "Wulong District" },
      { code: "500229", name: "Chengkou County" },
      { code: "500230", name: "Fengdu County" },
      { code: "500231", name: "Padjiang County" },
      { code: "500233", name: "Zhongxian" },
      { code: "500235", name: "yunyang county" },
      { code: "500236", name: "Fengjie County" },
      { code: "500237", name: "Wushan County" },
      { code: "500238", name: "Wuxi County" },
      { code: "500240", name: "Shizhu Tujia Autonomous County" },
      { code: "500241", name: "Xiushan Miao and Tujia Autonomous County" },
      { code: "500242", name: "Youyang Tujia and Miao Autonomous County" },
      { code: "500243", name: "Pengshui Miao and Tujia Autonomous County" },
    ],
  },
  {
    code: "51",
    name: "Sichuan Province",
    children: [
      { code: "5101", name: "Chengdu City" },
      { code: "5101", name: "Chengdu City" },
      { code: "5103", name: "Zigong City" },
      { code: "5104", name: "Panzhihua City" },
      { code: "5105", name: "Luzhou City" },
      { code: "5106", name: "Deyang City" },
      { code: "5106", name: "Deyang City" },
      { code: "5107", name: "Mianyang City" },
      { code: "5107", name: "Mianyang City" },
      { code: "5108", name: "Guangyuan City" },
      { code: "5109", name: "Suining City" },
      { code: "5110", name: "Neijiang City" },
      { code: "5111", name: "Leshan City" },
      { code: "5113", name: "Nanchong City" },
      { code: "5114", name: "Meishan City" },
      { code: "5115", name: "Yibin City" },
      { code: "5116", name: "Guang'an City" },
      { code: "5117", name: "Dazhou City" },
      { code: "5118", name: "Ya'an" },
      { code: "5119", name: "BaZhongCity" },
      { code: "5119", name: "BaZhongCity" },
      { code: "5120", name: "Ziyang City" },
      { code: "5120", name: "Ziyang City" },
      { code: "5132", name: "Aba Tibetan and Qiang Autonomous Prefecture" },
      { code: "5133", name: "Ganzi Tibetan Autonomous Prefecture" },
      { code: "5134", name: "Liangshan Yi Autonomous Prefecture" },
    ],
  },
  {
    code: "52",
    name: "Guizhou Province",
    children: [
      { code: "5201", name: "Guiyang City" },
      { code: "5202", name: "Liupanshui City" },
      { code: "5203", name: "Zunyi City" },
      { code: "5204", name: "Anshun City" },
      { code: "5205", name: "Bijie City" },
      { code: "5206", name: "Tongren City" },
      { code: "5223", name: "Qianxinanbuyi Miao Autonomous Prefecture" },
      { code: "5226", name: "Qiandongnan Miao and Dong Autonomous Prefecture" },
      { code: "5227", name: "Qiannanbuyi Miao Autonomous Prefecture" },
    ],
  },
  {
    code: "53",
    name: "Yunnan Province",
    children: [
      { code: "5301", name: "Kunming City" },
      { code: "5303", name: "Qujing City" },
      { code: "5304", name: "Yuxi City" },
      { code: "5305", name: "Baoshan City" },
      { code: "5306", name: "Zhaotong City" },
      { code: "5307", name: "Lijiang City" },
      { code: "5308", name: "Pu'er City" },
      { code: "5309", name: "Lincang City" },
      { code: "5323", name: "Chuxiong Yi Autonomous Prefecture" },
      { code: "5325", name: "Honghe Hani and Yi Autonomous Prefecture" },
      { code: "5326", name: "Wenshan Zhuangzumiao Autonomous Prefecture" },
      { code: "5328", name: "Xishuangbanna Dai Autonomous Prefecture" },
      { code: "5329", name: "Dali Bai Autonomous Prefecture" },
      { code: "5331", name: "Dehong Dai and Jingpo Autonomous Prefecture" },
      { code: "5333", name: "Nujiang Lisu Autonomous Prefecture" },
      { code: "5334", name: "Diqing Tibetan Autonomous Prefecture" },
    ],
  },
  {
    code: "54",
    name: "Tibet Autonomous Region",
    children: [
      { code: "5401", name: "Lhasa City" },
      { code: "5402", name: "Shigatse City" },
      { code: "5403", name: "Chamdo City" },
      { code: "5404", name: "Linzhi City" },
      { code: "5405", name: "Shannan City" },
      { code: "5406", name: "Nagchu City" },
      { code: "5425", name: "Ali Region" },
    ],
  },
  {
    code: "61",
    name: "Shaanxi Province",
    children: [
      { code: "6101", name: "Xi'an City" },
      { code: "6102", name: "Tongchuan City" },
      { code: "6103", name: "Baoji City" },
      { code: "6104", name: "Xianyang City" },
      { code: "6105", name: "Weinan City" },
      { code: "6106", name: "Yan'an City" },
      { code: "6107", name: "Hanzhong City" },
      { code: "6107", name: "Hanzhong City" },
      { code: "6108", name: "Yulin City" },
      { code: "6109", name: "Ankang City" },
      { code: "6110", name: "Shangluo City" },
    ],
  },
  {
    code: "62",
    name: "Gansu Province",
    children: [
      { code: "6201", name: "Lanzhou City" },
      { code: "6202", name: "Jiayuguan City" },
      { code: "6203", name: "Jinchang" },
      { code: "6204", name: "Silver City" },
      { code: "6205", name: "TSW" },
      { code: "6206", name: "Wuwei City" },
      { code: "6207", name: "Zhangye City" },
      { code: "6208", name: "Pingliang City" },
      { code: "6209", name: "Jiuquan City" },
      { code: "6210", name: "Qingyang City" },
      { code: "6211", name: "Dingxi City" },
      { code: "6212", name: "Longnan City" },
      { code: "6229", name: "Linxia Hui Autonomous Prefecture" },
      { code: "6230", name: "Gannan Tibetan Autonomous Prefecture" },
    ],
  },
  {
    code: "63",
    name: "Qinghai Province",
    children: [
      { code: "6301", name: "Xining City" },
      { code: "6302", name: "Haidong City" },
      { code: "6322", name: "Haibei Tibetan Autonomous Prefecture" },
      { code: "6323", name: "Huangnan Tibetan Autonomous Prefecture" },
      { code: "6325", name: "Hainan Tibetan Autonomous Prefecture" },
      { code: "6326", name: "Golo Tibetan Autonomous Prefecture" },
      { code: "6327", name: "Yushu Tibetan Autonomous Prefecture" },
      { code: "6328", name: "Haixi Mongol-Tibetan Autonomous Prefecture" },
    ],
  },
  {
    code: "64",
    name: "Ningxia Hui Autonomous Region",
    children: [
      { code: "6401", name: "Yinchuan City" },
      { code: "6402", name: "Shizuishan City" },
      { code: "6403", name: "Wuzhong City" },
      { code: "6404", name: "Goodyear" },
      { code: "6405", name: "CWS" },
    ],
  },
  {
    code: "65",
    name: "Xinjiang Uygur Autonomous Region",
    children: [
      { code: "6501", name: "Urumqi City" },
      { code: "6502", name: "Karamay City" },
      { code: "6504", name: "Tulufan City" },
      { code: "6505", name: "Hami City" },
      { code: "6523", name: "Changji Hui Autonomous Prefecture" },
      { code: "6527", name: "Börtala Mongol Autonomous Prefecture" },
      { code: "6528", name: "Bayin'guoleng Mongol Autonomous Prefecture" },
      { code: "6529", name: "Aksu Region" },
      { code: "6530", name: "Kizilsu-Kirghiz Autonomous Prefecture" },
      { code: "6531", name: "Kashgar Region" },
      { code: "6532", name: "Hotan Region" },
      { code: "6540", name: "Ili Kazak Autonomous Prefecture" },
      { code: "6542", name: "Tashkent Region" },
      { code: "6543", name: "Altay Region" },
      { code: "659001", name: "Shihezi City" },
      { code: "659002", name: "Alar City" },
      { code: "659003", name: "Tumxuk City" },
      { code: "659004", name: "Wujiaqu City" },
      { code: "659005", name: "Beitun City" },
      { code: "659006", name: "Tiemenguan City" },
      { code: "659007", name: "Twin Rivers" },
      { code: "659008", name: "City of Kokdara" },
      { code: "659009", name: "Kunyu City" },
      { code: "659010", name: "City of Poplar River" },
      { code: "659011", name: "City of Nova" },
      { code: "659012", name: "Poplar City" },
    ],
  },
];

export default pcC;
