export const QAList = {
  q1: {
    q: "Are you a donor interested in donating a kidney or a patient in need of a kidney transplant?" ,
    a_list: [
      {
        a: "Donor",
        result: "q2",
      },
      {
        a: "patient",
        result: "q02",
      },
    ],
  },
  q2: {
    q: "Are you at least 18 years old and fully capable of civil behaviour?" ,
    a_list: [
      {
        a: "Yes",
        result: "q3",
      },
      {
        a: "No",
        result: "r1",
      },
    ],
  },
  q3: {
    q: "What is your relationship to the patient who received the kidney donation?" ,
    a_list: [
      {
        a: "Immediate blood relatives: parents, children",
        result: "r2",
      },
      {
        a: "Other direct blood relatives: grandparents, grandchildren, grandchildren",
        result: "r3",
      },
      {
        a: "collateral relatives within three generations",
        result: "q6",
      },
      {
        a: "Couple",
        result: "q4",
      },
      {
        a: "Other",
        result: "r5",
      },
    ],
  },
  q4: {
    q: "How many years have you been married to the love of your life?" ,
    a_list: [
      {
        a: "three and more years",
        result: "r6",
      },
      {
        a: "up to three years",
        result: "q5",
      },
    ],
  },
  q5: {
    q: "Do you and your spouse have any children from your marriage?" ,
    a_list: [
      {
        a: "Yes",
        result: "r6",
      },
      {
        a: "No",
        result: "r7",
      },
    ],
  },
  q6: {
    q: "Please select a relationship",
    a_list: [
      {
        a: "Siblings",
        remark: "including half-siblings, half-siblings or half-siblings",
        result: "r4",
      },
      {
        a: "Siblings of parents",
        remark: "i.e. your uncles, aunts, uncles, aunts." ,
        result: "r4",
      },
      {
        a: "cousins",
        remark:
          "The children of your parents' brothers and sisters, i.e. the children of your father's brothers (cousins) and the children of your father's sisters (cousins)",
        result: "r4",
      },
      {
        a: "cousins",
        remark:
          "The children of your parents' brothers and sisters, i.e. the children of your mother's brothers (cousins) and the children of your mother's sisters (cousins)",
        result: "r4",
      },
      {
        a: "nieces and nephews, nephews and nieces",
        remark: "the children of your brothers and sisters",
        result: "r4",
      },
      {
        a: "aunts, uncles, aunts, uncles, aunts",
        remark: "the spouses of your parents' siblings",
        result: "r4",
      },
    ],
  },
  q02: {
    q: "Is there a loved one who would be willing to donate a kidney to you?" ,
    a_list: [
      {
        a: "Yes",
        result: "q03",
      },
      {
        a: "No",
        result: "r01",
      },
    ],
  },
  q03: {
    q: "Has he/she reached the age of 18 years",
    a_list: [
      {
        a: "Yes",
        result: "q04",
      },
      {
        a: "No",
        result: "r02",
      },
    ],
  },
  q04: {
    q: "3. What is the relationship between the person interested in donating a kidney and you?" ,
    a_list: [
      {
        a: "Immediate blood relatives: parents, children",
        result: "r03",
      },
      {
        a: "Other direct blood relatives: grandparents, grandchildren, grandchildren",
        result: "r04",
      },
      {
        a: "collateral relatives within three generations",
        result: "q07",
      },
      {
        a: "Couple",
        result: "q05",
      },
      {
        a: "Other",
        result: "r06",
      },
    ],
  },
  q05: {
    q: "How many years have you been married to the person you love",
    a_list: [
      {
        a: "three and more years",
        result: "r07",
      },
      {
        a: "up to three years",
        result: "q06",
      },
    ],
  },
  q06: {
    q: "Do you and your lover have children from your marriage",
    a_list: [
      {
        a: "Yes",
        result: "r07",
      },
      {
        a: "No",
        result: "r08",
      },
    ],
  },
  q07: {
    q: "Please select a relationship",
    a_list: [
      {
        a: "Siblings",
        remark: "including half-siblings, half-siblings or half-siblings",
        result: "r05",
      },
      {
        a: "Siblings of parents",
        remark: "i.e. your uncles, aunts, uncles, aunts." ,
        result: "r05",
      },
      {
        a: "cousins",
        remark:
          "The children of your parents' brothers and sisters, i.e. the children of your father's brothers (cousins) and the children of your father's sisters (cousins)",
        result: "r05",
      },
      {
        a: "cousins",
        remark:
          "The children of your parents' brothers and sisters, i.e. the children of your mother's brothers (cousins) and the children of your mother's sisters (cousins)",
        result: "r05",
      },
      {
        a: "nieces and nephews, nephews and nieces",
        remark: "the children of your brothers and sisters",
        result: "r05",
      },
      {
        a: "aunts, uncles, aunts, uncles, aunts",
        remark: "the spouses of your parents' siblings",
        result: "r05",
      },
    ],
  },
  r1: {
    type: "fail",
    r: "We are sorry that you are currently unable to perform the act of kidney donation. We appreciate your concern and love for your loved ones, but according to the Regulations on the Administration of Clinical Application of Human Organ Transplantation Technology, only citizens who have reached the age of 18 and have full capacity for civil behaviour are allowed to perform organ donation, so please reconsider when you are an adult and have full capacity for civil behaviour." ,
  },
  r2: {
    type: "success",
    r: "You meet the basic requirements for a relative to donate a kidney! Whether it's a parent's love for a child or a child's love for a parent, it's one of the greatest emotions in the world. But also please do a good job of related checks, kidney transplant not only to relationship close enough, but also kidney and body with good, now there is still a long way to go, I wish your family a speedy recovery." ,
  },
  r3: {
    type: "success",
    r: "You meet the basic requirements for relatives to donate kidneys, but you can't take it lightly, whether your kidneys are in good condition and whether they are suitable for your loved ones are unknown, so you have to do the relevant examinations, and I wish you can be a 'superhero' to save your family members." ,
  },
  r4: {
    type: "success",
    r: "According to the latest Regulations on the Administration of Clinical Application of Human Organ Transplantation Techniques, you meet the basic requirements for organ donation. However, whether you can organ donation not only depends on your own will, but also through the specific evaluation of the Ethics Committee, in addition you have the qualification of organ donation, but also need to verify whether your kidney is a match or not, the kidney road is long, I wish your family members can get well soon." ,
  },
  r5: {
    type: "fail",
    r: "We are sorry that you are unable to donate your kidney to the expected one, and we envy your deep friendship that allows you to donate your kidney for him/her, but according to the Regulations on the Administration of Clinical Application of Human Organ Transplantation Techniques, recipients of living organs are limited to the spouses, direct blood relatives, or collateral blood relatives within three generations of the living organ donor." ,
  },
  r6: {
    type: "success",
    r: "You can donate a kidney for your loved one! As the only feasible living kidney transplant for non-blood relatives under the legal framework of our country, your willingness to donate for your beloved is praiseworthy, but we also ask you to do the relevant examinations, and we hope that not only you and your beloved are emotionally matched, but also your kidney can be matched with his/her body, and we sincerely wish your beloved a speedy recovery." ,
  },
  r7: {
    type: "fail",
    r: "Although I envy your relationship with your loved one, according to the Regulations on the Administration of Clinical Application of Human Organ Transplantation Technology, you are not eligible for kidney donation, only those who have children after marriage or have been married for more than three years are allowed to donate kidneys. Please also don't be discouraged, not the only treatment pathway is kidney transplantation for relatives, and your loved one to adjust their mindset to accept other treatments, there may also be a good treatment effect!" ,
  },
  r01: {
    type: "fail",
    r: "I'm sorry, but currently the recipients of living organs in China are limited to the living organ donor's spouse, direct blood relatives or collateral blood relatives within three generations. You can actively communicate with your doctor to find other treatment options or wait for other ways to donate your kidney." ,
  },
  r02: {
    type: "fail",
    r: "I am sorry that you are unable to accept his/her kidney donation. I appreciate your loved one's concern and love for you, but according to the Regulations on the Administration of Clinical Application of Human Organ Transplantation Techniques, only citizens who have reached the age of 18 and have full capacity for civil behaviour are allowed to donate organs, and organ donation from minors is not desirable!" ,
  },
  r03: {
    type: "success",
    r: "You can accept kidney donations from both parents and children! Whether it's a parent's love for their child or a child's love for their parent, it's one of the greatest feelings in the world. But also please you and your family to do the relevant checks, kidney transplant not only to relationship close enough, but also kidney and body with good, now there is still a long way to go, I wish you a speedy recovery." ,
  },
  r04: {
    type: "success",
    r: "You and your relatives who are interested in donating kidneys meet the basic requirements for kidney donation by relatives, but you should not take it lightly, whether your loved one's kidneys are in good condition or not, whether they are suitable for you or not are unknown, you have to do the relevant checks, and hope that your body can also accept the love from your loved ones." ,
  },
  r05: {
    type: "success",
    r: "According to the latest Regulations on the Administration of Clinical Application of Human Organ Transplantation Technology, your loved one meets the basic requirements for organ donation. However, the possibility of organ donation depends not only on his/her wish, but also through the specific assessment of the Ethics Committee, in addition, it needs to be verified whether you are a match for the donor's kidney, the road to kidney is long, I wish you a speedy recovery." ,
  },
  r06: {
    type: "fail",
    r: "I'm sorry, you are not allowed to accept his/her kidney donation, I envy your deep friendship, no matter it's a couple, friend, relative or any other relationship, it's an admirable behaviour to donate kidney for you, but according to the Regulations on the Administration of Clinical Application of Human Organ Transplantation Techniques, recipients of living organs are limited to the spouses, direct blood relatives or collateral blood relatives within the third generation of the living organ donor. We also hope that you and the donor can understand",
  },
  r07: {
    type: "success",
    r: "You can accept a kidney donated to you by your loved one! Relative kidney transplantation between husband and wife is the only feasible non-blood related relative living kidney transplantation under the legal framework of our country, also ask her/him to do the relevant examination, hope not only you and your beloved are emotionally matched, but also her/his kidney can be matched with your body, I sincerely wish you a speedy recovery and a healthy and fulfilling life for both of you." ,
  },
  r08: {
    type: "fail",
    r: "Although I envy your relationship with your loved one, but according to the 'Human Organ Transplantation Technology Clinical Application Management Regulations', you are not your loved one can be the object of kidney donation, only those who have children after marriage or have been married for more than three years can be kidney donors. Please also don't be discouraged, not the only one treatment pathway for kidney transplantation for relatives, you have to adjust your mind to accept other treatment, which may also have good therapeutic effect!" ,
  },
};