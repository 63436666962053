export const userInfo = [
  {
    index: 1,
    name: "Tang Liangfeng",
    enrollment_time: 2000.9,
    graduation_time: 2007.7,
    supervisor: "Zhu Tongyu",
    address: "Shanghai Municipality",
    employer: "Paediatric Hospital of Fudan University/Surgery of Urology",
    office: null,
    post: "Associate Chief Physician (qualified as a renal implantologist)", 
    project: "Renal ischaemia-reperfusion injury and its improvement",
    photo: true,
    position: ["121.394971", "31.139147"],
  },
  {
    index: 2,
    name: "Li Long",
    enrollment_time: 2011.9,
    graduation_time: 2016.7,
    supervisor: "Zhu Tongyu/Rong Ruiming",
    address: "Shanghai Municipality",
    employer: "Department of Urology, Ninth People's Hospital, Shanghai Jiaotong University School of Medicine, Shanghai, China",
    office: null,
    post: "Deputy Chief Physician",
    project: "Immune tolerance in renal transplantation",
    photo: true,
    position: ["121.40", "31.21"]
  },
  {
    index: 3,
    name: "Li Jinfeng",
    enrollment_time: 2004.09,
    graduation_time: 2009.07,
    supervisor: "Zhu Tongyu, Wang Guomin",
    address: "Zhengzhou City",
    employer: "The First Affiliated Hospital of Zhengzhou University",
    office: null,
    post: "Chief Physician, Professor",
    project: "Kidney transplantation",
    photo: true,
    position: ["113.646042", "34.752734"],
  },
  {
    index: 4,
    name: "Yu Minhao",
    enrollment_time: 2001.9,
    graduation_time: 2008.7, 
    supervisor: "Zhu Tongyu",
    address: "Shanghai Municipality",
    employer: "Renji Hospital, Shanghai Jiaotong University School of Medicine",
    office: null,
    post: "Deputy Chief Physician",
    project: "Renal ischaemia-reperfusion injury",
    photo: true,
    position: ["121.484270", "31.233971"],
  },
  {
    index: 5,
    name: "Meng Jun",
    enrollment_time: 2000.9,
    graduation_time: 2007.7,
    supervisor: "Zhu Tongyu",
    address: "Shanghai Municipality",
    employer: "Wusong Hospital, Zhongshan Hospital, Fudan University",
    office: "OAPR",
    post: "Deputy Chief Physician",
    project: "Evaluation of the value of DNA methylation status of urinary sedimentary cells in the diagnosis of bladder cancer",
    photo: true,
    position: ["121.496298", "31.377933"],
  },
  {
    index: 6,
    name: "Li Jiawei",
    enrollment_time: 2015.9,
    graduation_time: 2021.7,
    supervisor: "Rong Ruiming",
    address: "Shanghai Municipality",
    employer: "abbvie",
    office: null,
    post: "senior clinical research physician",
    project: "Immune tolerance in renal transplantation",
    photo: true,
    position: ["121.463239", "31.228666"],
  },
  {
    index: 7,
    name: "Bloomberg",
    enrollment_time: 2008.9,
    graduation_time: 2016.7,
    supervisor: "Zhu Tongyu",
    address: "Changsha City",
    employer: "Xiangya Third Hospital of Central South University",
    office: null,
    post: "Attending physician",
    project: "Immune tolerance in renal transplantation",
    photo: true,
    position: ["112.944798", "28.220483"],
  },
  {
    index: 8,
    name: "Qiu Yue",
    enrollment_time: 2009.9,
    graduation_time: 2012.6,
    supervisor: "Rong Ruiming",
    "Current address (province/city)": "Qingdao City",
    employer: "Shandong University Qilu Hospital Qingdao Campus",
    office: null,
    post: "Deputy Chief Physician",
    project: "Renal ischaemia-reperfusion injury",
    photo: true,
    Position: ["117.018773", "36.656637"],
  },
  {
    index: 9,
    name: "Qi Ruochen",
    enrollment_time: 2018.9,
    "毕业时 间": 2021.6,
    supervisor: "Zhu Tongyu",
    address: "Xi'an City",
    employer: "Xijing Hospital of Air Force Military Medical University",
    office: null,
    post: "Attending physician",
    project: "Renal fibrosis",
    photo: true,
    position: ["108.9430", "34.2739"]
  },
  {
    index: 10,
    name: "Wang Ming",
    enrollment_time: 2006.9,
    graduation_time: 2009.6,
    supervisor: "Zhu Tongyu",
    address: "Hangzhou",
    employer: "The Second Hospital Affiliated to the Medical College of Zhejiang University",
    office: null,
    post: "Deputy Chief Physician",
    project: "Haematopoietic stem cell immune escape",
    photo: true,
    position: ["120.177439", "30.251172"],
  },
  {
    index: 11,
    name: "Gu Chenli",
    enrollment_time: 2007.9,
    graduation_time: 2015.7,
    supervisor: "Zhu Tongyu",
    address: "Shanghai Municipality",
    employer: "United Family Healthcare",
    office: null,
    post: "Director of Strategy",
    project: "Renal ischaemia-reperfusion injury",
    photo: true,
    position: ["121.369253", "31.205431"]
  },
  {
    index: 12,
    name: "Rulin Wang",
    enrollment_time: 2006.9,
    graduation_time: 2014.6,
    supervisor: "Xu Ming",
    address: "United States",
    employer: "Johns Hopkins University School of Medicine",
    office: null,
    post: "Research Associate",
    project: "Renal ischaemia-reperfusion injury",
    photo: true,
    position: ["-76.62103", "39.33008"]
  },
  {
    index: 13,
    name: "Lin Miao",
    enrolment_time: 2004.9,
    graduation_time: 2013.9,
    supervisor: "Zhu Tongyu/Rong Ruiming",
    address: "Shanghai Municipality",
    employer: "Zhongshan Hospital of Fudan University",
    office: null,
    post: "Deputy Chief Physician",
    project: "Renal ischaemia-reperfusion injury",
    photo: true,
    position: ["121.454965", "31.197318"],
  },
  {
    index: 14,
    name: "Tu Guowei",
    enrollment_time: 2001.9,
    graduation_time: 2008.7,
    supervisor: "Zhu Tongyu",
    address: "Shanghai Municipality",
    employer: "Zhongshan Hospital of Fudan University",
    office: null,
    post: "Chief Physician",
    project: "BK virus infection after kidney transplantation",
    photo: true,
    position: ["121.454965", "31.197318"],
  },
  {
    index: 15,
    name: "Li Haihao",
    enrollment_time: 2006.9,
    graduation_time: 2014.6,
    supervisor: "Zhu Tongyu",
    address: "Kunming, Yunnan Province",
    employer: "The Second Affiliated Hospital of Kunming Medical University",
    office: "Urology",
    post: "Associate Professor",
    project: "Immune tolerance in renal transplantation",
    photo: true,
    position: ["102.680211", "25.026262"],
  },
  {
    index: 16,
    name: "Qiu Yong Yin",
    enrolment_time: 2004.9,
    graduation_time: 2012.6,
    supervisor: "Zhu Tongyu",
    address: "Singapore",
    employer: "Singapore Central Hospital",
    office: "Department of Emergency Medicine",
    post: "Associate Consultant",
    project: "Immune tolerance in renal transplantation",
    photo: true,
    position: ["103.833947", "1.273867"]
  },
  {
    index: 17,
    name: "Lai Lik Ping",
    enrollment_time: 2012.9,
    graduation_time: 2016.1,
    supervisor: "Zhu Tongyu",
    address: "Danville, Pennsylvania, USA",
    employer: "Geisinger Medical Centre",
    office: "Pathology Section",
    post: "Residency",
    project: "Renal ischaemia-reperfusion injury",
    photo: true,
    position: ["-76.60575", "40.96772"]
  },
  {
    index: 18,
    name: "Chen Juntao",
    enrollment_time: 2020.9,
    graduation_time: 2023.6,
    supervisor: "Zhu Tongyu",
    address: "Hangzhou",
    employer: "The Second Hospital Affiliated to the Medical College of Zhejiang University",
    office: null,
    post: "Residency",
    project: "Renal ischaemia-reperfusion loss",
    photo: true,
    position: ["120.177439", "30.251172"],
  },
  {
    index: 19,
    name: "Zhang Chao",
    enrollment_time: 2012.9,
    graduation_time: 2019.6,
    supervisor: "Zhu Tongyu/Rong Ruiming",
    address: "Shanghai Municipality",
    employer: "Tumour Hospital Affiliated to Fudan University",
    office: null,
    post: "Attending physician",
    project: "Renal ischaemia-reperfusion injury",
    photo: null,
    position: ["121.369253", "31.205431"]
  },
  {
    index: 20,
    name: "Wang Xuelei",
    enrollment_time: 2002.9,
    graduation_time: 2005.6,
    supervisor: "Zhu Tongyu",
    address: "Shanghai Municipality",
    employer: "Dongfang Hospital, Tongji University",
    office: "Urology",
    post: "Chief Physician",
    project: "Stem cell immune escape",
    photo: true,
    position: ["121.513035", "31.238288"]
  },
  {
    index: 21,
    name: "Zhuo Ran",
    enrollment_time: 2019.9,
    graduation_time: 2022.6,
    supervisor: "Rong Ruiming",
    address: "Hong Kong",
    employer: "Faculty of Medicine, The University of Hong Kong",
    office: null,
    post: "Post-Bo",
    project: "Renal fibrosis",
    photo: null,
    position: ["114.1313", "22.2820"]
  },
  {
    index: 22,
    name: "GAURAB POKHREL",
    enrollment_time: 2011.9,
    graduation_time: 2014.6,
    supervisor: "Rong Ruiming",
    address: "NEPAL, KATHMANDU",
    employer: "HELPING HANDS COMMUNITY HOSPITAL",
    office: "UROLOGY",
    post: "Consultant",
    project: null,
    photo: null,
    position: ["85.3240", "27.7084"]
  },
  {
    index: 23,
    name: "Gao Wenjun",
    enrollment_time: 2017.9,
    graduation_time: 2021.6,
    supervisor: "Zhu Tongyu",
    address: "Hangzhou",
    employer: "The Second Hospital Affiliated to the Medical College of Zhejiang University",
    office: null,
    post: "Attending physician",
    project: "Renal ischaemia-reperfusion injury",
    photo: true,
    position: ["120.177439", "30.251172"],
  },
  {
    index: 24,
    name: "Chen Tian",
    enrollment_time: 2013.9,
    graduation_time: 2021.6,
    supervisor: "Rong Ruiming",
    address: "Singapore",
    employer: "Khoo Teck Puat Hospital (KTPH)",
    office: "Urology",
    post: "medical officer",
    project: "Renal ischaemia-reperfusion injury",
    photo: true,
    position: ["1.424635", "103.838208"]
  },
  {
    index: 25,
    name: "Luo Yongsheng",
    enrollment_time: 2020.9,
    graduation_time: 2023.6,
    supervisor: "Rong Ruiming",
    address: "Zhengzhou City, Henan Province",
    employer: "The First Affiliated Hospital of Zhengzhou University",
    office: "Renal Transplantation Unit",
    post: "Residency",
    project: "Renal fibrosis",
    photo: true,
    position: ["113.646042", "34.752734"],
  },
  {
    index: 26,
    name: "Zhang Chang",
    enrollment_time: 2000.9,
    graduation_time: 2007.7,
    supervisor: "Zhu Tongyu/Sun Lian",
    address: "Shanghai Municipality",
    employer: "Shanghai Tongren Hospital",
    office: "Teaching Subjects",
    post: "Attending physician",
    project: "Effects of rapamycin on renal ischaemia-reperfusion injury in rats",
    photo: null,
    position: ["121.3783", "31.2216"]
  },
  {
    index: 27,
    name: "Wang Shuo",
    enrollment_time: 2014.9,
    graduation_time: 2017.6,
    supervisor: "Zhu Tongyu",
    address: "Jinan City, Shandong Province",
    employer: "Shandong University Qilu Hospital",
    office: "Urology",
    post: "Attending physician",
    project: "Renal ischaemia-reperfusion injury",
    photo: true,
    Position: ["117.018773", "36.656637"],
  },
  {
    index: 28,
    name: "Xu Shihao",
    enrollment_time: 2019.9,
    graduation_time: 2022.6,
    supervisor: "Rong Ruiming",
    address: "Jinan City, Shandong Province",
    employer: "Provincial Hospital of Shandong First Medical University",
    office: "Urology",
    post: " Attending Physician",
    project: "Renal fibrosis",
    photo: true,
    position: ["116.9813", "36.6543"]
  },
  {
    index: 29,
    name: "Zhang Haojie",
    enrollment_time: 2009.9,
    graduation_time: 2014.6,
    supervisor: "Zhu Tongyu",
    address: "Shanghai",
    employer: "East China Hospital of Fudan University",
    office: "Urology",
    post: "Deputy Chief Physician",
    project: "Possible molecular mechanisms underlying the effects of Twist2 on renal cancer cell function and tumour progression",
    photo: true,
    position: ["121.4327", "31.2313"]
  },
  {
    index: 30,
    name: "Zhang Pingbao",
    enrollment_time: 2020.9,
    Graduation_time: 2023.12,
    supervisor: "Rong Ruiming",
    address: "Shanghai Municipality",
    employer: "Dongfang Hospital, Tongji University",
    office: "Urology",
    post: "Residency",
    project: "Renal fibrosis",
    photo: true,
    position: ["121.513035", "31.238288"]
  },
  {
    index: 31,
    name: "Wang Jiyan",
    enrollment_time: 2019.9,
    Graduation_time: 2022.12,
    supervisor: "Zhu Tongyu",
    address: "Shanghai",
    employer: "Zhongshan Hospital of Fudan University",
    office: "Laboratory Section",
    post: "Residency",
    project: "Renal ischaemia-reperfusion injury",
    photo: true,
    position: ["121.454965", "31.197318"],
  },
  {
    index: 32,
    name: "Sun Jiajia",
    enrollment_time: 2018.9,
    graduation_time: 2021.6,
    supervisor: "Zhu Tongyu",
    address: "Zhengzhou City",
    employer: "The First Affiliated Hospital of Zhengzhou University",
    office: "Renal Transplantation Unit",
    post: "Residency",
    project: "Prevention and control of BK polyomavirus infection",
    photo: true,
    position: ["113.646042", "34.752734"],
  },
  {
    index: 33,
    name: "Hsu Han-yen",
    enrollment_time: 2018.9,
    graduation_time: 2021.6,
    supervisor: "Xu Ming",
    address: "Shenzhen",
    employer: "The Eighth Affiliated Hospital of Sun Yat-sen University",
    office: "Emergency Department",
    post: "Residency",
    project: "Prevention and control of BK polyomavirus infection",
    photo: true,
    position: ["114.0564", "22.5455"]
  },
  {
    index: 34,
    name: "Zheng Long",
    enrollment_time: 2013.9,
    graduation_time: 2016.7,
    supervisor: "Rong Ruiming",
    address: "Hangzhou",
    employer: "The Second Hospital Affiliated to the Medical College of Zhejiang University",
    office: "Urology",
    post: "Attending physician",
    project: "Kidney transplantation and immune tolerance",
    photo: true,
    position: ["120.177439", "30.251172"],
  },
  {
    index: 35,
    name: "Zhang Lexi",
    enrollment_time: 2007.9,
    graduation_time: 2015.7,
    supervisor: "Rong Ruiming",
    address: "Hefei City",
    employer: "The First Hospital Affiliated to the Chinese University of Science and Technology (Anhui Provincial Hospital)",
    office: "Renal Transplantation Unit",
    post: "Attending physician",
    project: " Renal ischaemia-reperfusion injury",
    photo: true,
    position: ["117.2804", "31.8480"]
  },
  {
    index: 36,
    name: "Hu Mushang",
    enrollment_time: 2009.9,
    graduation_time: 2017.6,
    supervisor: "Zhu Tongyu",
    address: "Shanghai Municipality",
    employer: "Iwu Bio",
    office: null,
    post: "Managing Director of Subsidiary",
    project: "Human kidney telocyes",
    photo: true,
    position: ["120.0029", "30.6099"]
  },
  {
    index: 37,
    name: "Jiang Yamei",
    enrollment_time: 2019.9,
    graduation_time: 2023.6,
    supervisor: "Zhu Tongyu",
    address: "Beijing Municipality",
    employer: "Xuanwu Hospital of Capital Medical University",
    office: "Urology",
    post: "Residency",
    project: "Renal ischaemia-reperfusion injury",
    photo: true,
    position: ["116.362275", "39.891298"],
  },
  {
    index: 38,
    name: "Huang Shang",
    enrollment_time: 2007.9,
    graduation_time: 2015.7,
    supervisor: "Zhu Tongyu",
    address: "GuangZhou",
    employer: "Guangdong Provincial People's Hospital",
    office: "Urology",
    post: "Attending physician",
    project: "Renal ischaemia-reperfusion injury",
    photo: true,
    position: ["113.287272", "23.125087"],
  },
  {
    index: 39,
    name: "Zeng Yigang",
    enrollment_time: 2016.9,
    graduation_time: null,
    supervisor: "Zhu Tongyu",
    address: "Shanghai Municipality",
    employer: "Shanghai First People's Hospital",
    office: "Urology",
    post: "Attending physician",
    project: "Prevention and control of BK polyomavirus infection",
    photo: null,
    position: ["121.489273", "31.253352"],
  },
];;