import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/main/index.vue";
import HomeEN from "../views/main-en/index.vue";
import Customer from "../views/customer/index.vue";
import CustomerEN from "../views/customer-en/index.vue";
import Map from "../views/map/index.vue";
import MapEN from "../views/map-en/index.vue";
import Address from "../views/address/index.vue";
import AddressEN from "../views/address-en/index.vue";
import QA from "../views/qa/index.vue";
import QAEN from "../views/qa-en/index.vue";
import Graduate from "../views/graduate/index.vue";
import GraduateEN from "../views/graduate-en/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/home_en",
    name: "homeEN",
    component: HomeEN,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/customer",
    name: "customer",
    component: Customer,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/customer_en",
    name: "customerEN",
    component: CustomerEN,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/map",
    name: "map",
    component: Map,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/map_en",
    name: "mapEN",
    component: MapEN,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/address",
    name: "address",
    component: Address,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/address_en",
    name: "addressEN",
    component: AddressEN,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/qa",
    name: "qa",
    component: QA,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/qa_en",
    name: "qaEN",
    component: QAEN,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/graduate",
    name: "graduate",
    component: Graduate,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/graduate_en",
    name: "graduateEN",
    component: GraduateEN,
    meta: {
      keepAlive: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
